import { PlaneBufferGeometry, Vector3 } from "three";
import ListHotspots from "../../others/ListHotspots";
import HotSpotEffect from "../effects/HotSpotEffect";
import HoverMesh from "../effects/HoverMesh";
import useHotspotStore from "../store/hotspotsStore";

const HotSpotTransit = () => {
  const { TRANSIT } = ListHotspots();
  const transitHotspot = useHotspotStore.getState().transitHotspot;

  return (
    <>
      {/* <HoversTransit /> */}
      <HotSpotEffect
        hotspotData={transitHotspot[0]}
        position={new Vector3(1.31, 8, -38.15)}
        currentHotspot={TRANSIT}
      />
    </>
  );
};

const HoversTransit = () => {
  return (
    <>
      <HoverMesh
        position={[1.241, 0.65, -33.92]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(2.2, 1.4)}
      />
      <HoverMesh
        position={[1.241, 0.65, -42.068]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(2.2, 1.4)}
      />
      <HoverMesh
        position={[0.15, 0.65, -37.98]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(8.15, 1.4)}
      />
      <HoverMesh
        position={[2.339, 0.65, -37.98]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(8.15, 1.4)}
      />
      {/* <HoverMesh
        position={[-3.21, 1.4, -37.96]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(8.5, 3)}
      />
      <HoverMesh
        position={[-9.19, 1.4, -37.96]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(8.5, 3)}
      /> */}
      {/* <HoverMesh
        position={[-6.01, 1.5, -40.96]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(6, 2.5)}
      />
      <HoverMesh
        position={[-3.0, 1.5, -44.99]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(8, 2.5)}
      />
      <HoverMesh
        position={[-8.9, 1.5, -44.99]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(8, 2.5)}
      /> */}
    </>
  );
};

export default HotSpotTransit;
