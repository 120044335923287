/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { MeshBasicMaterial } from "three";
import useStore from "../store/mainStore";
import { Zones } from "../../others/ListZones";
import { useFrame } from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    Traffic1: THREE.Mesh;
    Traffic2: THREE.Mesh;
    Parks: THREE.Mesh;
    Transit: THREE.Mesh;
    Venues: THREE.Mesh;
    Building: THREE.Mesh;
  };
  materials: {};
};

export default function Model({ ...props }: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useGLTF(
    "https://smartworld.nttdata.com/gltf/hotspot/hotspotsHover.glb"
  ) as GLTFResult;
  const currentZone = useStore((state) => state.currentZone);
  let material: MeshBasicMaterial = new MeshBasicMaterial({
    color: "rgb(80, 180, 255)", //rgb(216,220,224)
    opacity: 1.0,
    transparent: true,
  });

  const speed = 2;

  useFrame((state, delta) => {
    material.opacity =
      (Math.sin(state.clock.getElapsedTime() * speed) + 1) / 2.5; //(sin(2*x) + 1) / 2
    // material.opacity = Math.sin(state.clock.getElapsedTime() + 1) / 2.0;
    // material.opacity = 0.7 + Math.sin(state.clock.getElapsedTime() * 3.5);
    // material.opacity = sin(time);
    // $mySin = ($mySin + 1) / 2.0;
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        visible={currentZone === Zones.TRAFFIC ? true : false}
        scale={[1, 1.8, 1]}
        geometry={nodes.Traffic1.geometry}
        material={material}
        position={[23.377069, 0, 2.440031]}
      />
      <mesh
        visible={currentZone === Zones.TRAFFIC ? true : false}
        scale={[1, 1.8, 1]}
        geometry={nodes.Traffic2.geometry}
        material={material}
        position={[-13.197073, 0, -28.960243]}
      />
      <mesh
        visible={currentZone === Zones.PARKS ? true : false}
        scale={[1, 4.6, 1]}
        geometry={nodes.Parks.geometry}
        material={material}
        position={[17.021488, 0.703213, -9.704521]}
      />
      <mesh
        visible={currentZone === Zones.TRANSIT ? true : false}
        scale={[1, 1.8, 1]}
        geometry={nodes.Transit.geometry}
        material={material}
        position={[0.766724, 0.436124, -37.911034]}
      />
      <mesh
        visible={currentZone === Zones.VENUES ? true : false}
        scale={[1, 3.2, 1]}
        geometry={nodes.Venues.geometry}
        material={material}
        position={[-10.02, 0, -40.507683]}
      />
      <mesh
        visible={currentZone === Zones.BUILDINGS ? true : false}
        scale={[1, 5.2, 1]}
        geometry={nodes.Building.geometry}
        material={material}
        position={[4.105756, 0, -3.519219]}
      />
    </group>
  );
}

useGLTF.preload(
  "https://smartworld.nttdata.com/gltf/hotspot/hotspotsHover.glb"
);
