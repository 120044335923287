// import { useHelper } from "@react-three/drei";
import { useHelper } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import {
  CameraHelper,
  DirectionalLight,
  DirectionalLightHelper,
  Vector3,
} from "three";

const LightManager = () => {
  const initialPosition: Vector3 = new Vector3(-10, 30, 20);
  const ref = useRef<DirectionalLight>();

  // useHelper(ref, DirectionalLightHelper, 5, "cyan");
  // const { scene } = useThree();

  // useEffect(() => {
  //   if (ref && ref.current) {
  //     const cameraHelper = new CameraHelper(ref.current.shadow.camera);
  //     scene.add(cameraHelper);
  //   }
  // }, [ref]);

  return (
    <>
      <directionalLight
        ref={ref}
        position={initialPosition}
        color={0xffffff}
        intensity={1.0}
        castShadow
        shadow-camera-left={-55}
        shadow-camera-top={45}
        shadow-camera-right={55}
        shadow-camera-bottom={-45}
        shadow-camera-near={1}
        shadow-camera-far={80}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        // shadow-normalBias={-0.5}
        shadow-bias={-0.002}
        // shadow-bias={0.0001}
      />

      <hemisphereLight intensity={0.3} />
    </>
  );
};

export default LightManager;
