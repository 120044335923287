import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import { Zones } from "../../others/ListZones";
import useModalStore from "../modals/StoreModals";
import useStore from "../store/mainStore";
import useZoneDescriptionStore from "../store/zonesStore";

const HeaderDesktop = () => {
  const variants = {
    show: {
      opacity: 1.0,
    },
    hidde: {
      opacity: 0,
    },
  };

  const openInfoModal = useModalStore((state) => state.openInfoModal);
  return (
    <>
      <div className="absolute top-0 left-0 z-10 w-full">
        <div className="bg-white">
          <motion.img
            variants={variants}
            transition={{
              duration: 0.75,
              ease: "easeInOut",
            }}
            initial={variants.show}
            animate={openInfoModal ? "hidde" : "show"}
            className="w-28 md:w-[10rem] py-1 ml-12"
            src="https://smartworld.nttdata.com/assets/images/ui/ntt_logo.png"
            alt="NTTLogo"
          />
        </div>
        <div className="bg-transparent relative">
          <div className="mt-8 ml-12">
            <h1 className="text-xl md:text-4xl font-RobotoRegular font-medium">
              Welcome to the <span className="text-[#176FC0]">Smart World</span>
            </h1>
            <p className="text-sm md:text-lg mt-3 font-RobotoRegular text-black">
              Explore our Smart Solutions <br /> and discover the outcomes.
            </p>
          </div>
          <div className="absolute top-0 right-0 mr-14">
            {!isMobile && (
              <>
                <div className="flex flex-col">
                  <div className="flex flex-row items-center">
                    <img
                      src="https://smartworld.nttdata.com/assets/images/ui/raton.svg"
                      className="w-8"
                      alt="esc"
                    />
                    <p className=" text-base font-RobotoRegular pl-2 mt-3 text-[#176FC0]">
                      Drag to rotate / Scroll to zoom
                    </p>
                  </div>
                  <div className="flex flex-row items-center mt-2">
                    <img
                      src="https://smartworld.nttdata.com/assets/images/ui/esc.svg"
                      className="w-8"
                      alt="esc"
                    />
                    <p className="text-base font-RobotoRegular pl-2 text-[#176FC0]">
                      Press ESC to reset the camera
                    </p>
                  </div>
                </div>
                {/* <div className="w-full">
                  <img
                    src="https://smartworld.nttdata.com/assets/images/ui/instructions3.svg"
                    className="mr-0 ml-auto"
                    style={{ width: "15vw" }}
                  />
                </div> */}
                {/* <div className="flex flex-row mt-3 items-center">
                  <img
                    src="https://smartworld.nttdata.com/assets/images/ui/raton.svg"
                    className="w-6"
                    alt="mouse"
                  />
                  <p className=" text-base font-RobotoRegular pl-2 text-[#176FC0]">
                    Drag to rotate / Scroll to zoom
                  </p>
                </div>
                <div className="flex flex-row mt-3 items-center">
                  <img
                    src="https://smartworld.nttdata.com/assets/images/ui/esc2.svg"
                    className="w-6"
                    alt="mouse"
                  />
                  <p className="text-base font-RobotoRegular pl-2 text-[#176FC0]">
                    Press ESC to reset the camera
                  </p>
                </div> */}
              </>
            )}
          </div>
        </div>
      </div>
      {/* <div className="absolute top-0 right-0 z-10">
        <motion.img
          variants={variants}
          transition={{
            duration: 0.75,
            ease: "easeInOut",
          }}
          initial={variants.show}
          animate={openInfoModal ? "hidde" : "show"}
          className="w-28 md:w-[15rem] mt-2 mr-2"
          src="https://smartworld.nttdata.com/assets/images/ui/ntt_logo.png"
          alt="NTTLogo"
        />
      </div>
      <motion.div
        className="absolute left-0 top-0 z-10"
        variants={variants}
        transition={{
          duration: 0.75,
          ease: "easeInOut",
        }}
        initial={variants.show}
        animate={openInfoModal ? "hidde" : "show"}
      >
        <div className="text-justify ml-10 md:ml-20 mt-14 md:mt-10">
          <div className="flex flex-col flex-grow">
            <HeaderDynamic />
          </div>
        </div>
      </motion.div> */}
    </>
  );
};

const HeaderDynamic = () => {
  const currentZone = useStore((state) => state.currentZone);
  const setCurrentZone = useStore((state) => state.setCurrentZone);
  const parksZoneDescription = useZoneDescriptionStore(
    (state) => state.parksZoneDescription
  );
  const trafficZoneDescription = useZoneDescriptionStore(
    (state) => state.trafficZoneDescription
  );
  const transitZoneDescription = useZoneDescriptionStore(
    (state) => state.transitZoneDescription
  );
  const venuesZoneDescription = useZoneDescriptionStore(
    (state) => state.venuesZoneDescription
  );
  const buildingsZoneDescription = useZoneDescriptionStore(
    (state) => state.buildingsZoneDescription
  );

  return (
    <>
      <h1 className="text-xl md:text-2xl font-medium">Welcome to our</h1>
      <h1 className="text-2xl md:text-3xl font-bold tracking-wide text-[#176FC0]">
        Smart city
      </h1>
      <p className="text-sm md:text-base mt-3 text-[#11277B]">
        Select a main category to <br /> start the experience
      </p>
    </>
    // ) : (
    //   <div className="">
    //     <button
    //       className="rounded-full border-[1px] border-black px-5 text-lg"
    //       onClick={() => setCurrentZone(Zones.EMPTY)}
    //     >
    //       ← Back{" "}
    //     </button>
    //     <h1 className="mt-5 text-3xl font-bold tracking-wide text-[#176FC0]">
    //       {currentZone === Zones.PARKS
    //         ? "Parks"
    //         : currentZone === Zones.TRAFFIC
    //         ? "Traffic"
    //         : currentZone === Zones.TRANSIT
    //         ? "Transit"
    //         : currentZone === Zones.VENUES
    //         ? "Venues"
    //         : currentZone === Zones.BUILDINGS
    //         ? "Buildings"
    //         : null}
    //     </h1>
    //     <p className="w-[90%] md:w-[20rem] mt-2 text-black font-semibold text-justify">
    //       {currentZone === Zones.PARKS
    //         ? parksZoneDescription
    //         : currentZone === Zones.TRAFFIC
    //         ? trafficZoneDescription
    //         : currentZone === Zones.TRANSIT
    //         ? transitZoneDescription
    //         : currentZone === Zones.VENUES
    //         ? venuesZoneDescription
    //         : currentZone === Zones.BUILDINGS
    //         ? buildingsZoneDescription
    //         : null}
    //     </p>
    //   </div>
  );
};

export default HeaderDesktop;
