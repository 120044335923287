import { OrbitControls } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { MathUtils, Matrix4, Quaternion, Vector3 } from "three";
import { DEG2RAD } from "three/src/math/MathUtils";
import ListHotspots, { IHotspot3D } from "../../others/ListHotspots";
import { Zones } from "../../others/ListZones";
import useStore from "../store/mainStore";

const ControlsManager = () => {
  const { camera } = useThree();
  const refControls = useRef<any>();

  const currentHotspot = useStore((state) => state.currentHotspot);
  const setCurrentHotspot = useStore((state) => state.setCurrentHotspot);
  const setCurrentZone = useStore((state) => state.setCurrentZone);

  const { INITIAL, EMPTY } = ListHotspots();

  useLayoutEffect(() => {
    console.log("Ponemos camara en inicio");
    camera.position.set(
      INITIAL.position.x,
      INITIAL.position.y,
      INITIAL.position.z
    );
    refControls.current.target.set(
      INITIAL.target.x,
      INITIAL.target.y,
      INITIAL.target.z
    );
    setCurrentHotspot(EMPTY);
    // moveToHotspot(INITIAL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    document.addEventListener(
      "keydown",
      (ev: KeyboardEvent) => {
        var code = ev.key;
        if (code === "m") {
          //setCurrentHotspot(INITIAL);
          console.log("pos", camera.position);
          console.log("target", refControls.current.target);
          console.log("zoom", refControls.current.getDistance());
          console.log("polar_angle", refControls.current.getPolarAngle());
          console.log("quaternion", camera.quaternion);
          // console.log("controls", refControls.current);
        }
        if (code === "Escape") {
          setCurrentHotspot(INITIAL);
          setCurrentZone(Zones.EMPTY);
        }
        if (code === "r") {
        }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFrame((_state, _delta) => {
    //Si no hay ningun hotspot elegido
    if (currentHotspot !== EMPTY) {
      // console.log("sigo moviendo");
      moveToHotspot(currentHotspot);
    }
  });

  document.addEventListener("mousedown", () => {
    setCurrentHotspot(EMPTY);
  });
  document.addEventListener("touchstart", () => {
    setCurrentHotspot(EMPTY);
  });

  /**
   * Mueve la camara al hotspot elegido
   * @param hotspot HOTSPOT A MOVERSE
   */
  function moveToHotspot(hotspot: IHotspot3D): void {
    if (refControls && refControls.current) {
      const newPosition = camera.position.lerp(hotspot.position, 0.015);
      const newTarget = refControls.current.target.lerp(hotspot.target, 0.015);
      refControls.current.target.set(newTarget.x, newTarget.y, newTarget.z);
      camera.position.set(newPosition.x, newPosition.y, newPosition.z);

      if (
        camera.position.manhattanDistanceTo(hotspot.position) <= 0.5 &&
        refControls.current.target.manhattanDistanceTo(hotspot.target) <= 0.5
      ) {
        // console.log("He llegado al final", hotspot);
        setCurrentHotspot(EMPTY);
      }
    }
  }

  const [enabled, setEnabled] = useState<boolean>(true);

  return (
    <OrbitControls
      ref={refControls}
      enableDamping
      dampingFactor={0.05}
      screenSpacePanning={false}
      enablePan={true}
      mouseButtons={{
        LEFT: 0,
        MIDDLE: 1,
        RIGHT: 0,
      }}
      minDistance={43} //10
      maxDistance={80} // {100} //{57}
      minZoom={1.0}
      maxZoom={200.0}
      maxPolarAngle={DEG2RAD * 80} //{Math.PI / 2} // Limitamos para que solo se pueda ver desde hacia arriba, isometrico o plano
      minPolarAngle={DEG2RAD * 40}
      camera={camera}
      enabled={enabled}
    />
  );
};

export default ControlsManager;
