import useStore from "../store/mainStore";
import Footer from "./Footer";
import HeaderDesktop from "./HeaderDesktop";

/**
 * Solo activa cuando la escena está terminada, es decir, se deja de mostrar la pantalla de loading
 *
 * @returns
 */
const UserInterface = () => {
  const sceneActive = useStore((state) => state.sceneActive);
  return (
    <>
      {sceneActive && (
        <>
          <HeaderDesktop />
          <Footer />
        </>
      )}
    </>
  );
};

export default UserInterface;
