import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Group, MathUtils } from "three";

interface FloaterProps {
  amplitude: number;
  frequency: number;
  offset?: [number, number, number];
  randomStart?: boolean;
  angularVelocity?: number;
}

const Floater: React.FC<FloaterProps> = ({
  amplitude,
  frequency,
  offset = [0, 0, 0],
  randomStart = false,
  angularVelocity,
  children,
}) => {
  const groupRef = useRef<Group>();
  const startTime = randomStart ? Math.random() * 10 : 0;

  useFrame((state, delta) => {
    if (!groupRef.current) return;

    groupRef.current.position.set(
      offset[0],
      offset[1] +
        Math.sin((state.clock.elapsedTime + startTime) * frequency) * amplitude,
      offset[2]
    );

    if (angularVelocity) {
      groupRef.current.rotation.y +=
        angularVelocity * MathUtils.DEG2RAD * delta;
    }
  });

  return (
    <group position={offset} ref={groupRef}>
      {children}
    </group>
  );
};

export default Floater;
