import useHotspotStore, { ISlides } from "../components/store/hotspotsStore";
import useZoneDescriptionStore from "../components/store/zonesStore";
import { Zones } from "./ListZones";

const DEBUG_MODE = false;

export default function getInfoData() {
  const INFO_FILE_PATH = "https://smartworld.nttdata.com/content/info.json";
  try {
    // Obtenemos el fichero json local
    fetch(INFO_FILE_PATH)
      .then((response) => response.json())
      .then((json) => {
        // Aqui tenemos el JSON parseado ya
        // console.log(json);
        // Por cada zona, obtenemos la info de cada hotspot
        // parksZone
        parseHotspots(Zones.PARKS, Object.entries(json.parks));
        // trafficZone
        parseHotspots(Zones.TRAFFIC, Object.entries(json.traffic));
        // transitZone
        parseHotspots(Zones.TRANSIT, Object.entries(json.transit));
        // venues
        parseHotspots(Zones.VENUES, Object.entries(json.venues));
        //buildings
        parseHotspots(Zones.BUILDINGS, Object.entries(json.buildings));
      });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function parseHotspots(zone: Zones, arrayHotspots: any): void {
  console.log("Parseamos Hotspot");
  const setParksHotspot = useHotspotStore.getState().setParksHotspot;
  const setTrafficHotspot = useHotspotStore.getState().setTrafficHotspot;
  const setTransitHotspot = useHotspotStore.getState().setTransitHotspot;
  const setVenuesHotspot = useHotspotStore.getState().setVenuesHotspot;
  const setBuildingsHotspot = useHotspotStore.getState().setBuildingsHotspot;

  const setParksZoneDescription =
    useZoneDescriptionStore.getState().setParksZoneDescription;
  const setTrafficZoneDescription =
    useZoneDescriptionStore.getState().setTrafficZoneDescription;
  const setTransitZoneDescription =
    useZoneDescriptionStore.getState().setTransitZoneDescription;
  const setVenuesZoneDescription =
    useZoneDescriptionStore.getState().setVenuesZoneDescription;
  const setBuildingsZoneDescription =
    useZoneDescriptionStore.getState().setBuildingsZoneDescription;

  arrayHotspots.forEach((hotspot: any, index: number) => {
    if (hotspot[0].includes("description")) {
      switch (zone) {
        case Zones.PARKS:
          setParksZoneDescription(hotspot[1]);
          break;
        case Zones.TRAFFIC:
          setTrafficZoneDescription(hotspot[1]);
          break;
        case Zones.TRANSIT:
          setTransitZoneDescription(hotspot[1]);
          break;
        case Zones.VENUES:
          setVenuesZoneDescription(hotspot[1]);
          break;
        case Zones.BUILDINGS:
          setBuildingsZoneDescription(hotspot[1]);
          break;
      }
      return;
    }

    // Slides[]
    var Slides: ISlides[] = [];
    var longName: string = "";
    Object.values(hotspot[1]).forEach((info: any, i: number) => {
      if (i === 0) {
        // Primer elemento, es el nombre largo
        longName = info;
      } else {
        //Cada slide del hotspot
        Slides.push({ type: info.type, url: info.url, desc: info.description });
      }
    });
    if (DEBUG_MODE) console.log(hotspot[0], Slides);
    switch (zone) {
      case Zones.PARKS:
        setParksHotspot({
          name: hotspot[0] as string,
          long_name: longName,
          slides: Slides,
        });
        break;
      case Zones.TRAFFIC:
        setTrafficHotspot({
          name: hotspot[0] as string,
          long_name: longName,
          slides: Slides,
        });
        break;
      case Zones.TRANSIT:
        setTransitHotspot({
          name: hotspot[0] as string,
          long_name: longName,
          slides: Slides,
        });
        break;
      case Zones.VENUES:
        setVenuesHotspot({
          name: hotspot[0] as string,
          long_name: longName,
          slides: Slides,
        });
        break;
      case Zones.BUILDINGS:
        setBuildingsHotspot({
          name: hotspot[0] as string,
          long_name: longName,
          slides: Slides,
        });
        break;
      default:
        break;
    }
  });
}
