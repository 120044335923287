import { useLoader, useThree } from "@react-three/fiber";
import { CubeTextureLoader } from "three";
// import px from "../../assets/cubemap/px.jpg";
// import nx from "../../assets/cubemap/nx.jpg";
// import py from "../../assets/cubemap/py.jpg";
// import ny from "../../assets/cubemap/ny.jpg";
import pz from "../../assets/cubemap/pz.jpg";
// import nz from "../../assets/cubemap/nz.jpg";
import { useEffect } from "react";

const CubemapManager = () => {
  //@ts-ignore
  // const [envMap] = useLoader(CubeTextureLoader, [[px, nx, py, ny, pz, nz]]);
  const [envMap] = useLoader(CubeTextureLoader, [[pz, pz, pz, pz, pz, pz]]);

  const { scene } = useThree();

  useEffect(() => {
    scene.background = envMap;
    //scene.environment = envMap;
  }, [envMap, scene]);

  return null;
};

export default CubemapManager;
