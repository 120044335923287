/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import { useLayoutEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Autobus: THREE.Mesh;
    Camion: THREE.Mesh;
    Coche_policia: THREE.Mesh;
    Tranvia: THREE.Mesh;
    Coche: THREE.Mesh;
    Coche2: THREE.Mesh;
    Autobus1: THREE.Mesh;
    Autobus2: THREE.Mesh;
    Coche3: THREE.Mesh;
    Coche4: THREE.Mesh;
    AutobusParado: THREE.Mesh;
    Coche5: THREE.Mesh;
    Camion2: THREE.Mesh;
    Coche_policia2: THREE.Mesh;
    Camion3: THREE.Mesh;
  };
  materials: {
    ["Material vehículosnuevos.001"]: THREE.MeshStandardMaterial;
  };
};

export default function Model({ ...props }: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, animations } = useGLTF(
    "https://smartworld.nttdata.com/gltf/traffic/traffic260122_1.glb"
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);
  useLayoutEffect(() => {
    const velocity = 30;
    actions["Action"]?.reset().play().setDuration(velocity);
    actions["Action.001"]?.reset().play().setDuration(velocity);
    actions["Action.002"]?.reset().play().setDuration(velocity);
    actions["Action.003"]?.reset().play().setDuration(velocity);
    actions["Action.004"]?.reset().play().setDuration(velocity);
    actions["Action.005"]?.reset().play().setDuration(velocity);
    actions["Action.006"]?.reset().play().setDuration(velocity);
    actions["Action.008"]?.reset().play().setDuration(velocity);
    actions["Action.010"]?.reset().play().setDuration(velocity);
    actions["Action.013"]?.reset().play().setDuration(velocity);
    actions["Action.015"]
      ?.reset()
      .play()
      .setDuration(velocity / 1.8);
    actions["Action.017"]
      ?.reset()
      .play()
      .setDuration(velocity / 1.75);
    actions["Action.019"]
      ?.reset()
      .play()
      .setDuration(velocity / 1.5);
    actions["TranviaAction"]?.reset().play().setDuration(velocity);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        name="Autobus"
        geometry={nodes.Autobus.geometry}
        material={nodes.Autobus.material}
        position={[10.616928, -0.000006, 6.104471]}
        rotation={[0, 0.000005, -Math.PI]}
        scale={[-1, -1, -1]}
      />
      <mesh
        castShadow
        receiveShadow
        name="Camion"
        geometry={nodes.Camion.geometry}
        material={nodes.Camion.material}
        position={[-23.101322, 0, -2.176425]}
        rotation={[0, -1.570535, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        name="Coche_policia"
        geometry={nodes.Coche_policia.geometry}
        material={nodes.Coche_policia.material}
        position={[-6.629315, 0, -10.281027]}
        rotation={[-Math.PI, 0.000001, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        name="Tranvia"
        geometry={nodes.Tranvia.geometry}
        material={nodes.Tranvia.material}
        position={[26.964314, 0, -37.97924]}
        rotation={[-Math.PI, 1.557857, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        name="Coche"
        geometry={nodes.Coche.geometry}
        material={nodes.Coche.material}
        position={[-11.943398, 0, 6.206583]}
      />
      <mesh
        castShadow
        receiveShadow
        name="Coche2"
        geometry={nodes.Coche2.geometry}
        material={nodes.Coche2.material}
        position={[-0.800468, 0, -1.737199]}
        rotation={[0, 1.570535, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        name="Autobus1"
        geometry={nodes.Autobus1.geometry}
        material={nodes.Autobus1.material}
        position={[-12.452966, 0, 24.111664]}
      />
      <mesh
        castShadow
        receiveShadow
        name="Autobus2"
        geometry={nodes.Autobus2.geometry}
        material={nodes.Autobus2.material}
        position={[12.689471, 0, 7.740166]}
        rotation={[Math.PI, -0.000001, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        name="Coche3"
        geometry={nodes.Coche3.geometry}
        material={nodes.Coche3.material}
        position={[-40.979218, 0, 25.770571]}
        rotation={[Math.PI, -0.000002, Math.PI]}
        scale={0}
      />
      <mesh
        castShadow
        receiveShadow
        name="Coche4"
        geometry={nodes.Coche4.geometry}
        material={nodes.Coche4.material}
        position={[-20.135048, 0, -34.601124]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.AutobusParado.geometry}
        material={nodes.AutobusParado.material}
        position={[1.19154, -0.000006, -34.995674]}
        rotation={[Math.PI, -Math.PI / 2, 0]}
        scale={[-1, -1, -1]}
      />
      <mesh
        castShadow
        receiveShadow
        name="Coche5"
        geometry={nodes.Coche5.geometry}
        material={nodes.Coche5.material}
        position={[-24.647121, 0, -47.361248]}
        rotation={[0, 1.570535, 0]}
        scale={0}
      />
      <mesh
        castShadow
        receiveShadow
        name="Camion2"
        geometry={nodes.Camion2.geometry}
        material={nodes.Camion2.material}
        position={[-41.957462, 0, -28.228329]}
        rotation={[Math.PI, -0.000003, Math.PI]}
        scale={0}
      />
      <mesh
        castShadow
        receiveShadow
        name="Coche_policia2"
        geometry={nodes.Coche_policia2.geometry}
        material={nodes.Coche_policia2.material}
        position={[-43.249001, 0, -10.212756]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0}
      />
      <mesh
        castShadow
        receiveShadow
        name="Camion3"
        geometry={nodes.Camion3.geometry}
        material={nodes.Camion3.material}
        position={[-42.442036, 0, 7.794389]}
        rotation={[-Math.PI, 0.003257, -Math.PI]}
        scale={0}
      />
    </group>
  );
}

useGLTF.preload(
  "https://smartworld.nttdata.com/gltf/traffic/traffic260122_1.glb"
);
