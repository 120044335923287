import { Vector3 } from "three";
import create from "zustand";
import { IHotspot3D } from "../../others/ListHotspots";
import { Zones } from "../../others/ListZones";

type IStore = {
  currentHotspot: IHotspot3D;
  setCurrentHotspot: (value: IHotspot3D) => void;

  currentZone: Zones;
  setCurrentZone: (value: Zones) => void;

  sceneActive: boolean;
  setSceneActive: (value: boolean) => void;
};

const useStore = create<IStore>((set) => ({
  currentHotspot: {
    target: new Vector3(0, 0, 0),
    position: new Vector3(-64.05, 32.15, -35.54),
  },
  setCurrentHotspot: (value: IHotspot3D) =>
    set(() => ({ currentHotspot: value })),

  currentZone: Zones.EMPTY,
  setCurrentZone: (value: Zones) => set(() => ({ currentZone: value })),

  sceneActive: false,
  setSceneActive: (value: boolean) => set(() => ({ sceneActive: value })),
}));

export default useStore;
