import { useProgress } from "@react-three/drei";

const cleanPercentage = (percentage: number) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;

  const tooHigh = percentage > 100;

  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

// const BackgroundCircle = () => {
//   const r = 70;

//   return (
//     <circle
//       r={r}
//       cx={100}
//       cy={100}
//       fill="white"
//       strokeLinecap="round"
//       style={{
//         transition: "all 0.7s ease-in-out",
//       }}
//     />
//   );
// };

// const InnerCircle = ({ pct }: { pct: number }) => {
//   const r = 70;

//   const circ = 2 * Math.PI * r;

//   const strokePct = ((100 - pct) * circ) / 100;

//   return (
//     <circle
//       className={`animate-pulse z-10`}
//       r={r}
//       cx={100}
//       cy={100}
//       fill="transparent"
//       stroke={strokePct !== circ ? "url(#gradient)" : ""} // remove colour as 0% sets full circumference
//       strokeWidth={"0.25rem"}
//       strokeDasharray={circ}
//       strokeDashoffset={pct ? strokePct : 0}
//       strokeLinecap="round"
//       style={{
//         transition: "all 0.7s ease-in-out",
//       }}
//     />
//   );
// };

// const OuterCircle = ({ pct }: { pct: number }) => {
//   const r = 70;

//   const circ = 2 * Math.PI * r;

//   const strokePct = ((100 - pct) * circ) / 100;

//   return (
//     <circle
//       r={r}
//       cx={100}
//       cy={100}
//       fill="transparent"
//       stroke={strokePct !== circ ? "white" : ""} // remove colour as 0% sets full circumference
//       strokeWidth={"0.8rem"}
//       strokeDasharray={circ}
//       strokeDashoffset={0}
//       strokeLinecap="round"
//       style={{
//         zIndex: 60,
//         transition: "all 0.7s ease-in-out",
//       }}
//     />
//   );
// };

// const ShadowCircle = () => {
//   const r = 100;

//   return (
//     <circle
//       r={r}
//       cx={100}
//       cy={100}
//       fill="url(#gradientRadial)"
//       strokeLinecap="round"
//       style={{
//         transition: "all 0.7s ease-in-out",
//       }}
//     />
//   );
// };

const Text = ({ percentage }: { percentage: number }) => {
  return (
    <text
      // className="font-semibold animate-pulse"
      className="font-semibold animate-pulse"
      width={200}
      x="50%"
      y="80%"
      dominantBaseline="central"
      textAnchor="middle"
      // fontSize={"2em"}
      fontSize={"2em"}
      // fill="url(#gradientText)"
      fill="#176FC0"
    >
      Loading... {percentage.toFixed(0)}%
    </text>
  );
};

const Pie = () => {
  /**
   * React Three Drei
   */
  const { progress } = useProgress();

  const pct = cleanPercentage(progress);

  return (
    <>
      <svg
        version="1.1"
        id="レイヤー_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={200}
        height={200}
        viewBox="0 0 59.4 57"
        xmlSpace="preserve"
        className="flex absolute  inset-1/2 z-10"
        style={{ transform: "translate(-50%, -75%)" }}
      >
        <defs>
          <mask id="mask">
            <path
              fill={"#ffffff"}
              d="M0.1,27c0,16.7,13.7,29.6,29.7,29.6c16,0,29.7-12.9,29.7-29.6c0-15.3-10.7-26.5-22.5-26.5
		c-2.7,0-5.3,0.6-7.2,1.3c-1.9-0.8-4.5-1.3-7.2-1.3C10.7,0.5,0.1,11.7,0.1,27 M35,19.3c0,3.2-2.1,5.8-5.3,5.8
		c-3.1,0-5.3-2.6-5.3-5.8c0-4.4,3-8.4,5.3-9.7C32,10.9,35,14.9,35,19.3z M7.4,26.8c0-11.5,8.3-19.4,14.9-19.1c-3.1,3.1-5,7.6-5,11.9
		c0,7.5,5.9,12.7,12.5,12.7c6.5,0,12.5-5.3,12.5-12.7c0-4.4-1.9-8.8-5-11.9c6.5-0.3,14.9,7.6,14.9,19.1c0,12.6-10.1,22.5-22.4,22.5
		C17.5,49.3,7.4,39.5,7.4,26.8z"
            />
          </mask>
        </defs>
        <rect width="200" height="200" fill="#dbdbdb" mask="url(#mask)" />
        <rect
          width="200"
          height="200"
          y={57 - Math.round(57 * (pct / 100))}
          fill="#007BC2"
          mask="url(#mask)"
        />
      </svg>
      <svg
        className="flex absolute inset-1/2   z-10"
        style={{ transform: "translate(-50%, -40%)" }}
        width={240}
        height={200}
      >
        <Text percentage={pct} />
      </svg>
      <div className="flex absolute inset-x-0 bottom-0 w-full z-10">
        {/* <div
          className="flex flex-col mx-auto bg-slate-100 max-w-full md:max-w-[40%] px-2 md:px-12 py-3 md:py-7 xl:mb-4 2xl:mb-[10rem]"
          // style={{ transform: "translateY(-75%)" }}
        >
          <h1 className="font-bold text-xl text-blue-600">
            Did you know that...?
          </h1>
          <p className="font-medium text-justify">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            accumsan quis massa vestibulum lobortis. Vestibulum sed odio nec
            magna accumsan condimentum.
          </p>
        </div> */}
      </div>
    </>

    //57 es 0,
    // 0 es 100%

    // <svg
    //   className="flex absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10"
    //   width={200}
    //   height={200}
    // >
    //   <defs>
    //     <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
    //       <stop offset="0%" stopColor="#f10c0c" />
    //       <stop offset="100%" stopColor="#7417ca" />
    //     </linearGradient>

    //     <linearGradient id="gradientText" x1="0%" y1="0%" x2="100%" y2="0%">
    //       <stop offset="0%" stopColor="#f10c0c" />
    //       <stop offset="100%" stopColor="#7417ca" />
    //     </linearGradient>

    //     <radialGradient id="gradientRadial">
    //       <stop offset="0%" stopColor="#666666" />
    //       <stop offset="70%" stopColor="#666666" />
    //       <stop offset="100%" stopColor="#ffffff00" />
    //     </radialGradient>
    //   </defs>

    //   <g transform={`rotate(-90 ${"100 100"})`}>
    //     <ShadowCircle />
    //     <BackgroundCircle />
    //     <OuterCircle pct={100} />
    //     <InnerCircle pct={pct} />
    //   </g>

    // </svg>
  );
};

export default Pie;
