import useModalStore from "./StoreModals";
import Modal from "react-modal";
const VideoModal = () => {
  const openVideoModal = useModalStore((state) => state.openVideoModal);
  const setOpenVideoModal = useModalStore((state) => state.setOpenVideoModal);

  function closeModal() {
    setOpenVideoModal(false);
  }

  return (
    <Modal
      isOpen={openVideoModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      style={{
        content: {
          // backgroundColor: "white",
          position: "absolute",
          zIndex: 15,
          padding: 0,
          // margin: "0.25rem",
          height: "90vh",
          // height: "80vh",
          // background: "rgb(243 244 246)",
          // overflow: "hidden",
        },
        overlay: {
          position: "absolute",
          zIndex: 14,
        },
      }}
    >
      <div className="w-full">
        <div className="flex justify-end items-center pb-3">
          <div className="modal-close cursor-pointer z-10">
            <svg
              className="fill-current text-black mt-4 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              onClick={() => closeModal()}
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </div>
        </div>
        <video className="w-[70vw] mx-auto border-2" controls autoPlay>
          <source src="https://smartworld.nttdata.com/assets/video/videoIntro.mp4" />
        </video>
      </div>
    </Modal>
  );

  // return openVideoModal ? (
  //   <Modal
  //     open={openVideoModal}
  //     showCloseIcon={false}
  //     closeOnOverlayClick
  //     closeOnEsc
  //     onClose={() => closeModal()}
  //   >
  //     <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center ">
  //       <div
  //         className="modal-overlay absolute w-full h-full bg-gray-900 opacity-60"
  //         onClick={() => closeModal()}
  //       ></div>

  //       <div className="modal-container bg-white w-11/12 md:max-w-[100rem] mx-auto rounded shadow-lg z-10 h-full overflow-y-auto">
  //         <div className="modal-content py-4 text-left px-6 ">
  //           <div className="flex justify-end items-center pb-3">
  //             {/* <p className="text-2xl font-bold ">Learn more about SMART:</p> */}
  //             <div className="modal-close cursor-pointer z-10">
  //               <svg
  //                 className="fill-current text-black"
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 width="18"
  //                 height="18"
  //                 viewBox="0 0 18 18"
  //                 onClick={() => closeModal()}
  //               >
  //                 <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
  //               </svg>
  //             </div>
  //           </div>

  //           <video className="w-full" controls autoPlay>
  //             <source src="https://smartworld.nttdata.com/assets/video/videoIntro.mp4" />
  //           </video>
  //         </div>
  //       </div>
  //     </div>
  //   </Modal>
  // ) : null;
};

export default VideoModal;
