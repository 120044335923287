import useModalStore from "./StoreModals";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useLayoutEffect, useState } from "react";
import useHotspotStore from "../store/hotspotsStore";
import { Zones } from "../../others/ListZones";
import useStore from "../store/mainStore";
import { isIPad13, isMobile, isTablet } from "react-device-detect";
import ListHotspots from "../../others/ListHotspots";
import Modal from "react-modal";

const InfoModal = () => {
  const openInfoModal = useModalStore((state) => state.openInfoModal);

  useLayoutEffect(() => {
    console.log(openInfoModal);
  }, [openInfoModal]);

  const setOpenInfoModal = useModalStore((state) => state.setOpenInfoModal);
  const setCurrentHotspot = useStore.getState().setCurrentHotspot;
  const { INITIAL } = ListHotspots();

  const currentModalHotspot = useHotspotStore(
    (state) => state.currentModalHotspot
  );

  function closeModal() {
    setCurrentHotspot(INITIAL);

    setSlideNumber(0);
    setOpenInfoModal(false);
  }

  const [slideNumber, setSlideNumber] = useState<number>(0);
  const [maxSlideNumber, setMaxSlideNumber] = useState<number>(0);
  const currentZone = useStore((state) => state.currentZone);

  useEffect(() => {
    setMaxSlideNumber(currentModalHotspot.slides.length);
  }, [currentModalHotspot]);

  // Modal de informacion para PC
  function InfoModalDesktop() {
    return (
      <>
        <div className="w-full">
          <img
            src="assets/images/ui/ntt_logo.png"
            className="w-[10%] rounded-full cursor-pointer ml-8"
          />
        </div>
        <div className="w-full relative bg-[rgb(186,210,237)] py-5">
          <h1 className="text-3xl ml-12 font-RobotoBold font-bold text-[#0072BC]">
            {currentZone === Zones.PARKS
              ? "Parks"
              : currentZone === Zones.TRAFFIC
              ? "Traffic"
              : currentZone === Zones.TRANSIT
              ? "Transit"
              : currentZone === Zones.VENUES
              ? "Venues"
              : currentZone === Zones.BUILDINGS
              ? "Buildings"
              : null}
          </h1>
          <div className="absolute top-0 right-0 mr-0 ml-auto">
            <div className="p-3">
              <img
                src="assets/images/ui/X.png"
                className="w-[8%] rounded-full mr-0 ml-auto cursor-pointer"
                alt="X"
                onClick={closeModal}
              />
            </div>
          </div>
        </div>
        <div
          className="w-full h-full bg-gray-white grid overflow-hidden px-14"
          style={{ gridTemplateColumns: "4fr 6fr" }}
        >
          {/* SLIDER */}
          <div className="w-full bg-white ">
            <div className="w-full mx-auto ">
              <div id="carousel" className="flex flex-row mt-10 w-[50%]">
                <img
                  src="assets/images/ui/flechaIzq.png"
                  className="w-[1.5vw]  cursor-pointer object-contain"
                  alt="←"
                  onClick={() => {
                    slideNumber === 0
                      ? setSlideNumber(maxSlideNumber - 1)
                      : setSlideNumber(slideNumber - 1);
                  }}
                />
                <div
                  className={`grid mx-6 gap-3 align-middle content-center`}
                  style={{
                    gridTemplateColumns: `repeat(${maxSlideNumber}, minmax(0,1fr))`,
                  }}
                >
                  {Array.from(Array(maxSlideNumber).keys()).map(
                    (value: number) => (
                      <span
                        key={value}
                        className={`${
                          slideNumber === value
                            ? "text-[#176FC0]"
                            : "text-black"
                        }
                        text-base font-RobotoMedium`}
                      >
                        {value + 1}
                      </span>
                    )
                  )}
                </div>
                <img
                  src="assets/images/ui/flechaDer.png"
                  className="w-[1.5vw]  cursor-pointer object-contain"
                  alt="→"
                  onClick={() => {
                    slideNumber === maxSlideNumber - 1
                      ? setSlideNumber(0)
                      : setSlideNumber(slideNumber + 1);
                  }}
                />
              </div>

              <div id="title" className="mt-14">
                <h1 className="text-4xl font-RobotoMedium text-black">
                  {currentModalHotspot.long_name}
                </h1>
              </div>
              <div id="description" className="mt-3 pr-12">
                <p className="font-RobotoRegular text-boardingXL font-medium">
                  {currentModalHotspot.slides[slideNumber].desc}
                </p>
              </div>
            </div>
          </div>

          {/* IMAGEN */}
          <div className="w-full">
            <div className="w-full mx-auto mt-16 aspect-video">
              {currentModalHotspot.slides[slideNumber].type === "image" ? (
                <img
                  src={currentModalHotspot.slides[slideNumber].url}
                  className="w-full h-full  object-contain aspect-video"
                  alt=""
                />
              ) : currentModalHotspot.slides[slideNumber].type === "video" ? (
                <video controls className="shadow-2xl">
                  <source
                    src={currentModalHotspot.slides[slideNumber].url}
                    type="video/mp4"
                  />
                  Your browser does not support HTML video.
                </video>
              ) : currentModalHotspot.slides[slideNumber].type === "iframe" ? (
                <iframe
                  className="w-full h-full shadow-2xl"
                  src={currentModalHotspot.slides[slideNumber].url}
                  title="IFRAME"
                ></iframe>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  function InfoModalMobile() {
    return (
      <div className="justify-center  flex flex-col overflow-x-hidden overflow-y-auto fixed inset-0 my-auto h-full w-full z-10 bg-white">
        <div className="absolute top-0 right-0 mr-0 ml-auto ">
          <div className="p-3">
            <img
              src="assets/images/ui/X.png"
              className="w-[8%] rounded-full mr-0 ml-auto cursor-pointer"
              alt="X"
              onClick={closeModal}
            />
          </div>
        </div>

        <div className="mt-11 w-full aspect-video ">
          {currentModalHotspot.slides[slideNumber].type === "image" ? (
            <img
              src={currentModalHotspot.slides[slideNumber].url}
              className="w-full h-full shadow-2xl object-contain aspect-video"
              alt=""
            />
          ) : currentModalHotspot.slides[slideNumber].type === "video" ? (
            <video controls className="w-full shadow-2xl">
              <source
                src={currentModalHotspot.slides[slideNumber].url}
                type="video/mp4"
              />
              Your browser does not support HTML video.
            </video>
          ) : currentModalHotspot.slides[slideNumber].type === "iframe" ? (
            <iframe
              className="w-full h-full shadow-2xl"
              src={currentModalHotspot.slides[slideNumber].url}
              title="IFRAME"
            ></iframe>
          ) : (
            <></>
          )}
        </div>

        <div className="flex flex-col md:w-1/3 lg:w-1/5 mx-auto px-5 ">
          <div id="carousel" className="flex flex-row mt-10">
            <img
              src="assets/images/ui/flechaIzq.png"
              className="w-[15%] ml-0 mr-auto cursor-pointer"
              alt="←"
              onClick={() => {
                slideNumber === 0
                  ? setSlideNumber(maxSlideNumber - 1)
                  : setSlideNumber(slideNumber - 1);
              }}
            />
            <div
              className={`grid mx-auto gap-3 align-middle content-center`}
              style={{
                gridTemplateColumns: `repeat(${maxSlideNumber}, minmax(0,1fr))`,
              }}
            >
              {Array.from(Array(maxSlideNumber).keys()).map((value: number) => (
                <span
                  key={value}
                  className={`${
                    slideNumber === value ? "text-[#176FC0]" : "text-black"
                  } `}
                >
                  {value}
                </span>
              ))}
            </div>
            <img
              src="assets/images/ui/flechaDer.png"
              className="w-[15%] ml-auto mr-0 cursor-pointer"
              alt="→"
              onClick={() => {
                slideNumber === maxSlideNumber - 1
                  ? setSlideNumber(0)
                  : setSlideNumber(slideNumber + 1);
              }}
            />
          </div>
          <div id="title" className="mt-14">
            <h1 className="text-2xl font-bold text-[#0072BC]">
              {currentZone === Zones.PARKS
                ? "Parks"
                : currentZone === Zones.TRAFFIC
                ? "Traffic"
                : currentZone === Zones.TRANSIT
                ? "Transit"
                : currentZone === Zones.VENUES
                ? "Venues"
                : currentZone === Zones.BUILDINGS
                ? "Buildings"
                : null}
            </h1>
          </div>
          <div id="title" className="mt-2">
            <h1 className="text-xl font-bold text-black">
              {currentModalHotspot.long_name}
            </h1>
          </div>
          <div id="description" className="mt-3">
            <p className="text-justify text-sm font-medium">
              {currentModalHotspot.slides[slideNumber].desc}
            </p>
          </div>
        </div>
      </div>
    );
  }

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <Modal
      isOpen={openInfoModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={true}
      style={{
        content: {
          // backgroundColor: "white",
          position: "absolute",
          zIndex: 15,
          padding: 0,
          // margin: "0.25rem",
          height: "90vh",
          // height: "80vh",
          // background: "rgb(243 244 246)",
          // overflow: "hidden",
        },
        overlay: {
          position: "absolute",
          zIndex: 14,
        },
      }}
    >
      <AnimatePresence>
        <motion.div
          variants={variants}
          animate={openInfoModal ? "visible" : "hidden"}
          // initial={{ opacity: 0, scale: 1 }}
          // animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 5,
          }}
        >
          {/* <div className="absolute w-1/2 h-1/2 bg-white">
            <h1>HOLA</h1>
          </div> */}
          {isMobile && !isTablet ? <InfoModalMobile /> : <InfoModalDesktop />}

          {/* <div className="w-full absolute z-10 bg-white">
            <h1>HOLA</h1>
          </div> */}
        </motion.div>
      </AnimatePresence>
    </Modal>
  );
};

export default InfoModal;
