import create from "zustand";

type IStore = {
  openInfoModal: boolean;
  setOpenInfoModal: (value: boolean) => void;

  openVideoModal: boolean;
  setOpenVideoModal: (value: boolean) => void;
};

const useModalStore = create<IStore>((set) => ({
  openInfoModal: false,
  setOpenInfoModal: (value: boolean) => set(() => ({ openInfoModal: value })),
  openVideoModal: false,
  setOpenVideoModal: (value: boolean) => set(() => ({ openVideoModal: value })),
}));

export default useModalStore;
