import { PlaneBufferGeometry, Vector3 } from "three";
import ListHotspots from "../../others/ListHotspots";
import HotSpotEffect from "../effects/HotSpotEffect";
import HoverMesh from "../effects/HoverMesh";
import useHotspotStore from "../store/hotspotsStore";

const HotSpotParks = () => {
  const { PARKS1 } = ListHotspots();
  const parksHotspot = useHotspotStore.getState().parksHotspot;

  return (
    <>
      {/* <HoversParks /> */}
      <HotSpotEffect
        hotspotData={parksHotspot[0]}
        position={new Vector3(15.81, 8, -9)}
        currentHotspot={PARKS1}
      />
    </>
  );
};

const HoversParks = () => {
  return (
    <>
      <HoverMesh
        position={[17, 1.5, -1.01]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(8.3, 2.5)}
      />
      <HoverMesh
        position={[17, 1.5, -17.01]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(8.3, 2.5)}
      />
      <HoverMesh
        position={[21.021, 1.5, -8.91]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(16, 2.5)}
      />
      <HoverMesh
        position={[13.02, 1.5, -8.91]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(16, 2.5)}
      />
    </>
  );
};

export default HotSpotParks;
