import create from "zustand";

type IStore = {
  parksHotspot: IHotspotStore[];
  setParksHotspot: (hotspot: IHotspotStore) => void;

  trafficHotspot: IHotspotStore[];
  setTrafficHotspot: (hotspot: IHotspotStore) => void;

  transitHotspot: IHotspotStore[];
  setTransitHotspot: (hotspot: IHotspotStore) => void;

  venuesHotspot: IHotspotStore[];
  setVenuesHotspot: (hotspot: IHotspotStore) => void;

  buildingsHotspot: IHotspotStore[];
  setBuildingsHotspot: (hotspot: IHotspotStore) => void;

  currentModalHotspot: IHotspotStore;
  setCurrentModalHotspot: (hotspot: IHotspotStore) => void;
};

export interface IHotspotStore {
  name: string; // Nombre del hotspot
  long_name: string;
  slides: ISlides[];
}

export interface ISlides {
  type: string;
  url: string;
  desc: string;
}

const useHotspotStore = create<IStore>((set) => ({
  parksHotspot: [],
  setParksHotspot: (hotspot: IHotspotStore) =>
    set((state) => ({ parksHotspot: [...state.parksHotspot, hotspot] })),

  trafficHotspot: [],
  setTrafficHotspot: (hotspot: IHotspotStore) =>
    set((state) => ({ trafficHotspot: [...state.trafficHotspot, hotspot] })),

  transitHotspot: [],
  setTransitHotspot: (hotspot: IHotspotStore) =>
    set((state) => ({ transitHotspot: [...state.transitHotspot, hotspot] })),

  venuesHotspot: [],
  setVenuesHotspot: (hotspot: IHotspotStore) =>
    set((state) => ({ venuesHotspot: [...state.venuesHotspot, hotspot] })),

  buildingsHotspot: [],
  setBuildingsHotspot: (hotspot: IHotspotStore) =>
    set((state) => ({
      buildingsHotspot: [...state.buildingsHotspot, hotspot],
    })),

  currentModalHotspot: { name: "", long_name: "", slides: [] },
  setCurrentModalHotspot: (hotspot: IHotspotStore) =>
    set(() => ({ currentModalHotspot: hotspot })),
}));

export default useHotspotStore;
