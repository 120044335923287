import create from "zustand";

type IStore = {
  parksZoneDescription: string;
  setParksZoneDescription: (description: string) => void;

  trafficZoneDescription: string;
  setTrafficZoneDescription: (description: string) => void;

  transitZoneDescription: string;
  setTransitZoneDescription: (description: string) => void;

  venuesZoneDescription: string;
  setVenuesZoneDescription: (description: string) => void;

  buildingsZoneDescription: string;
  setBuildingsZoneDescription: (description: string) => void;
};

const useZoneDescriptionStore = create<IStore>((set) => ({
  parksZoneDescription: "",
  setParksZoneDescription: (description: string) =>
    set(() => ({ parksZoneDescription: description })),

  trafficZoneDescription: "",
  setTrafficZoneDescription: (description: string) =>
    set(() => ({ trafficZoneDescription: description })),

  transitZoneDescription: "",
  setTransitZoneDescription: (description: string) =>
    set(() => ({ transitZoneDescription: description })),

  venuesZoneDescription: "",
  setVenuesZoneDescription: (description: string) =>
    set(() => ({ venuesZoneDescription: description })),

  buildingsZoneDescription: "",
  setBuildingsZoneDescription: (description: string) =>
    set(() => ({ buildingsZoneDescription: description })),
}));

export default useZoneDescriptionStore;
