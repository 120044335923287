import InfoModal from "./components/modals/InfoModal";
import UserInterface from "./components/ui/UserInterface";
import Scene from "./Scene";
import { MemoryRouter, Route, Routes } from "react-router-dom";
import { routes } from "./components/managers/RoutesManager";
import OnBoarding from "./components/ui/OnBoarding";
import { useEffect, useLayoutEffect } from "react";
import getInfoData from "./others/ObtainData";
import bg from "https://smartworld.nttdata.com/assets/images/ui/backgroundOnboarding.jpg";
import VideoModal from "./components/modals/VideoModal";
import useModalStore from "./components/modals/StoreModals";

function App() {
  useEffect(() => {
    getInfoData();
  }, []);

  return (
    <>
      <InfoModal />
      <VideoModal />

      <div
        id="app"
        className="absolute inset-0 bg-no-repeat bg-center bg-cover overflow-y-hidden"
        // style={{ backgroundImage: `url(${bg})` }}
      >
        <MemoryRouter>
          <Routes>
            <Route path={routes.ONBOARDING} element={<OnBoarding />}></Route>
            <Route
              path={routes.SCENE}
              // path={routes.ONBOARDING}
              element={
                <>
                  <UserInterface />
                  <Scene />
                </>
              }
            />
          </Routes>
        </MemoryRouter>
      </div>
    </>
  );
}

export default App;
