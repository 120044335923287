import { PlaneBufferGeometry, Vector3 } from "three";
import ListHotspots from "../../others/ListHotspots";
import HotSpotEffect from "../effects/HotSpotEffect";
import HoverMesh from "../effects/HoverMesh";
import useHotspotStore from "../store/hotspotsStore";

const HotSpotVenues = () => {
  const { VENUES } = ListHotspots();
  const venuesHotspot = useHotspotStore.getState().venuesHotspot;

  return (
    <>
      {/* <HoversVenues /> */}
      <HotSpotEffect
        hotspotData={venuesHotspot[0]}
        position={new Vector3(-9.93, 8, -40.508)}
        currentHotspot={VENUES}
      />
    </>
  );
};

const HoversVenues = () => {
  return (
    <>
      <HoverMesh
        position={[-9.43, 2.0, -33.605]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(9.92, 4)}
      />
      <HoverMesh
        position={[-9.43, 2.0, -47.46]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(9.92, 4)}
      />
      <HoverMesh
        position={[-14.378, 2.0, -40.577]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(13.82, 4)}
      />
      <HoverMesh
        position={[-4.577, 2.0, -40.577]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(13.82, 4)}
      />
      {/* <HoverMesh
        position={[-6.01, 1.5, -40.96]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(6, 2.5)}
      />
      <HoverMesh
        position={[-3.0, 1.5, -44.99]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(8, 2.5)}
      />
      <HoverMesh
        position={[-8.9, 1.5, -44.99]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(8, 2.5)}
      /> */}
    </>
  );
};

export default HotSpotVenues;
