import { animated, config, useSpring } from "@react-spring/three";
import { Billboard, Text, useTexture } from "@react-three/drei";
import { useState } from "react";
import { Color, Texture, Vector3 } from "three";
import { IHotspot3D } from "../../others/ListHotspots";
import useModalStore from "../modals/StoreModals";
import useStore from "../store/mainStore";
import Floater from "./Floater";
import hotspotImage from "../../assets/images/ui/hotspot.png";
import hotspotPlaceholderImage from "../../assets/images/ui/hotspot_placeholder.png";
import useHotspotStore, { IHotspotStore } from "../store/hotspotsStore";

interface IHotspotEffect {
  position: Vector3;
  currentHotspot: IHotspot3D;
  hotspotData: IHotspotStore;
}

const HotSpotEffect: React.FC<IHotspotEffect> = ({
  position,
  currentHotspot,
  hotspotData,
}) => {
  const [active, setActive] = useState(false);

  const { scale } = useSpring({
    scale: active ? 0.9 : 0.8,
    config: config.wobbly,
  });

  const textureHotspot: Texture = useTexture(hotspotImage);
  const texturePlaceHolderHotspot: Texture = useTexture(
    hotspotPlaceholderImage
  );
  const setCurrentHotspot = useStore((state) => state.setCurrentHotspot);
  const setOpenInfoModal = useModalStore.getState().setOpenInfoModal;
  const setCurrentModalHotspot =
    useHotspotStore.getState().setCurrentModalHotspot;

  function openModal() {
    setCurrentHotspot(currentHotspot);
    setCurrentModalHotspot(hotspotData);
    setTimeout(() => {
      setOpenInfoModal(true);
    }, 750);
  }

  const color: Color = new Color("rgb(80, 180, 255)");

  return (
    <Floater amplitude={0.5} frequency={3}>
      <group position={position}>
        <Billboard follow={true}>
          <animated.mesh
            position={[0, 0, 0]}
            scale={scale}
            onPointerLeave={() => setActive(false)}
            onPointerOver={() => setActive(true)}
            onClick={() => {
              openModal();
            }}
          >
            <planeGeometry args={[6, 6]} />
            <meshBasicMaterial
              map={textureHotspot}
              transparent
              // depthTest={false}
            />
          </animated.mesh>
          <animated.mesh
            position={[0, 3.75, 0.5]}
            scale={scale}
            onPointerLeave={() => setActive(false)}
            onPointerOver={() => setActive(true)}
            onClick={() => {
              openModal();
            }}
          >
            <planeGeometry args={[10, 2]} />
            <meshBasicMaterial
              map={texturePlaceHolderHotspot}
              // color={"rgb(80, 180, 255)"}
              transparent
              // depthTest={false}
            />
          </animated.mesh>
          <animated.mesh
            position={[0, 3.75, 0.6]}
            scale={scale}
            onPointerLeave={() => setActive(false)}
            onPointerOver={() => setActive(true)}
            onClick={() => {
              openModal();
            }}
          >
            <Text color="black" anchorX="center" anchorY="middle" scale={10}>
              {hotspotData.name}
            </Text>
          </animated.mesh>
        </Billboard>
      </group>
    </Floater>
  );
};

export default HotSpotEffect;
