/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Estadio: THREE.Mesh;
    Suelo: THREE.Mesh;
    Paradas_Bus: THREE.Mesh;
    Hospital: THREE.Mesh;
    Bus_Station: THREE.Mesh;
    Lift1: THREE.Mesh;
    Airquality: THREE.Mesh;
    Lift2: THREE.Mesh;
    RecicleArea: THREE.Mesh;
    Jail: THREE.Mesh;
    Electric_Station: THREE.Mesh;
    Oficina: THREE.Mesh;
    Park: THREE.Mesh;
    Columpio1: THREE.Mesh;
    Columpio2: THREE.Mesh;
    Universidad: THREE.Mesh;
    ShoppingCenter: THREE.Mesh;
    Decorado: THREE.Mesh;
    Factory: THREE.Mesh;
    Edificios002: THREE.Mesh;
  };
  materials: {
    ["Material Estadio"]: THREE.MeshStandardMaterial;
    ["Material Suelo"]: THREE.MeshStandardMaterial;
    ["Material Paradas Bus"]: THREE.MeshStandardMaterial;
    ["Material Hospital"]: THREE.MeshStandardMaterial;
    ["Material Bus Center"]: THREE.MeshStandardMaterial;
    ["Material Factory"]: THREE.MeshStandardMaterial;
    ["Material Airquality"]: THREE.MeshStandardMaterial;
    ["Material RecicleArea"]: THREE.MeshStandardMaterial;
    ["Material Jail"]: THREE.MeshStandardMaterial;
    ["Material Electric Center"]: THREE.MeshStandardMaterial;
    ["Material Oficina"]: THREE.MeshStandardMaterial;
    ["Material Park"]: THREE.MeshStandardMaterial;
    ["Material Decorado"]: THREE.MeshStandardMaterial;
    ["Material Universidad"]: THREE.MeshStandardMaterial;
    ["Material ShoppingCenter"]: THREE.MeshStandardMaterial;
    ["Material Edificios"]: THREE.MeshStandardMaterial;
  };
};

export default function Model({ ...props }: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials } = useGLTF(
    "https://smartworld.nttdata.com/gltf/city/CIUDAD21.glb"
  ) as GLTFResult;
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Estadio.geometry}
        material={materials["Material Estadio"]}
        position={[-9.281377, 1.613594, -40.473267]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={1.670674}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Suelo.geometry}
        material={materials["Material Suelo"]}
        position={[0, 0, 7]}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Paradas_Bus.geometry}
        material={materials["Material Paradas Bus"]}
        position={[-19, 0.594334, 9]}
        scale={[0.038157, 0.546533, 0.043401]}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Hospital.geometry}
        material={materials["Material Hospital"]}
        position={[3.438455, 5.174187, -8.883681]}
        scale={0.127122}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Bus_Station.geometry}
        material={materials["Material Bus Center"]}
        position={[6.481143, 1.396079, 43.514042]}
        scale={[0.820042, 0.890899, 1.195804]}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Lift1.geometry}
        material={nodes.Lift1.material}
        position={[-35.190239, 0.036617, -19.824461]}
        rotation={[0, 0.667969, 0]}
        scale={0.036618}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Airquality.geometry}
        material={materials["Material Airquality"]}
        position={[-9.729187, 0, 45.811989]}
        scale={0.016899}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Lift2.geometry}
        material={nodes.Lift2.material}
        position={[-34.456917, 0.036618, -22.037617]}
        rotation={[0, -0.928861, 0]}
        scale={0.036618}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.RecicleArea.geometry}
        material={materials["Material RecicleArea"]}
        position={[-29.970724, 1.29117, -42.822685]}
        scale={[0.079954, 0.029863, 0.079954]}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Jail.geometry}
        material={materials["Material Jail"]}
        position={[-33.903122, 0.931129, -15.385345]}
        rotation={[0, 1.570535, 0]}
        scale={[0.572969, 0.916616, 0.676297]}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Electric_Station.geometry}
        material={materials["Material Electric Center"]}
        position={[-29.51433, 2.102867, 17.606226]}
        scale={[0.317781, 0.060399, 0.320446]}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Oficina.geometry}
        material={materials["Material Oficina"]}
        position={[4.15, 1.049999, -4.150001]}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Park.geometry}
        material={materials["Material Park"]}
        position={[17, 0.06, -5]}
        rotation={[0, Math.PI / 2, 0]}
        scale={4}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Columpio1.geometry}
        material={nodes.Columpio1.material}
        position={[17, 0.052, -5]}
        rotation={[0, Math.PI / 2, 0]}
        scale={4}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Columpio2.geometry}
        material={nodes.Columpio2.material}
        position={[17, 0.052, -5]}
        rotation={[0, Math.PI / 2, 0]}
        scale={4}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Universidad.geometry}
        material={materials["Material Universidad"]}
        position={[-16.841774, 2.023474, -15.805123]}
        scale={[0.580558, 0.179906, 0.580558]}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.ShoppingCenter.geometry}
        material={materials["Material ShoppingCenter"]}
        position={[-5.39815, 3.173655, 37.437004]}
        scale={0.841274}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Decorado.geometry}
        material={nodes.Decorado.material}
        position={[20.619022, 0.739995, 32.021755]}
        scale={0.028037}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Factory.geometry}
        material={nodes.Factory.material}
        position={[-40.12038, 1.751993, -19.633331]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.580558, 0.179906, 0.580558]}
      />
      <mesh
        receiveShadow
        castShadow
        geometry={nodes.Edificios002.geometry}
        material={materials["Material Edificios"]}
        position={[4, 4.475, -16]}
      />
    </group>
  );
}

useGLTF.preload("https://smartworld.nttdata.com/gltf/city/CIUDAD21.glb");
