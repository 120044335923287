import { PlaneBufferGeometry, Vector3 } from "three";
import ListHotspots from "../../others/ListHotspots";
import HotSpotEffect from "../effects/HotSpotEffect";
import HoverMesh from "../effects/HoverMesh";
import useHotspotStore from "../store/hotspotsStore";

const HotSpotBuildings = () => {
  const { BUILDINGS } = ListHotspots();
  const buildingsHotspot = useHotspotStore.getState().buildingsHotspot;

  return (
    <>
      {/* <HoversBuildings /> */}
      <HotSpotEffect
        hotspotData={buildingsHotspot[0]}
        position={new Vector3(4.067, 8, -3.318)}
        currentHotspot={BUILDINGS}
      />
    </>
  );
};

const HoversBuildings = () => {
  return (
    <>
      <HoverMesh
        position={[4.16, 3.5, -1.26]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(2.38, 5.5)}
      />
      <HoverMesh
        position={[4.166, 3.5, -5.18]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(2.38, 5.5)}
      />
      <HoverMesh
        position={[3.068, 3.5, -3.25]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(3.94, 5.5)}
      />
      <HoverMesh
        position={[5.33, 3.5, -3.25]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(3.94, 5.5)}
      />
    </>
  );
};

export default HotSpotBuildings;
