import { useState } from "react";
import { Vector3 } from "three";

export interface IHotspot3D {
  target: Vector3;
  position: Vector3;
}

const ListHotspots = () => {
  const EMPTY: IHotspot3D = {
    target: new Vector3(0, 0, 0),
    position: new Vector3(0, 0, 0),
  };

  const INITIAL: IHotspot3D = {
    target: new Vector3(0, 0, 0),
    position: new Vector3(-64.05, 32.15, -35.54),
  };

  const PARKS1: IHotspot3D = {
    target: new Vector3(0, 0, 0),
    position: new Vector3(40.37, 7.94, -18.84),
  };

  const TRAFFIC1: IHotspot3D = {
    target: new Vector3(0, 0, 0),
    position: new Vector3(
      28.384591401771107,
      8.31699221020452,
      -37.67141925227518
    ),
  };
  const TRAFFIC2: IHotspot3D = {
    target: new Vector3(0, 0, 0),
    position: new Vector3(-22.96, 7.85, -38.2),
  };

  const TRANSIT: IHotspot3D = {
    target: new Vector3(0, 0, 0),
    position: new Vector3(1.36, 9.64, -54.71),
  };

  const VENUES: IHotspot3D = {
    target: new Vector3(0, 0, 0),
    position: new Vector3(-15.83, 10.11, -55.13),
  };

  const BUILDINGS: IHotspot3D = {
    target: new Vector3(0, 0, 0),
    position: new Vector3(-10.09, 22.41, -35.28),
  };

  const [ListHotspotsManager] = useState({
    EMPTY,
    INITIAL,
    PARKS1,
    TRAFFIC1,
    TRAFFIC2,
    TRANSIT,
    VENUES,
    BUILDINGS,
  });

  return ListHotspotsManager;
};

export default ListHotspots;
