import { Zones } from "../../others/ListZones";
import HotSpotBuildings from "../hotspots/HotspotBuildings";
import HotSpotParks from "../hotspots/HotspotParks";
import HotSpotTraffic from "../hotspots/HotspotTraffic";
import HotSpotTransit from "../hotspots/HotspotTransit";
import HotSpotVenues from "../hotspots/HotspotVenues";
import useStore from "../store/mainStore";

const ZonesEffect = () => {
  const currentZone = useStore((state) => state.currentZone);

  return currentZone === Zones.PARKS ? (
    <HotSpotParks />
  ) : currentZone === Zones.TRAFFIC ? (
    <HotSpotTraffic />
  ) : currentZone === Zones.TRANSIT ? (
    <HotSpotTransit />
  ) : currentZone === Zones.VENUES ? (
    <HotSpotVenues />
  ) : currentZone === Zones.BUILDINGS ? (
    <HotSpotBuildings />
  ) : null;
};

export default ZonesEffect;
