import { extend, useFrame, useLoader, useThree } from "@react-three/fiber";
import { useMemo, useRef } from "react";
import * as THREE from "three";
import { Water } from "three-stdlib";

extend({ Water });

const WaterEffect = () => {
  const ref = useRef();
  const gl = useThree((state) => state.gl);
  const waterNormals = useLoader(
    THREE.TextureLoader,
    "https://smartworld.nttdata.com/assets/images/waterNormals.jpg"
  );
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  const geom = useMemo(() => new THREE.PlaneGeometry(50, 50), []);
  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x0cb7f2, //0x001e0f,
      distortionScale: 3.7,
      fog: false,
      //@ts-ignore
      format: gl.encoding,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [waterNormals]
  );
  useFrame(
    //@ts-ignore
    (state, delta) => (ref.current.material.uniforms.time.value += delta * 0.1)
  );

  return (
    //@ts-ignore
    <water
      ref={ref}
      args={[geom, config]}
      position={[-8, -0.1, -3]}
      rotation-x={-Math.PI / 2}
    />
  );
};

export default WaterEffect;
