import { Html } from "@react-three/drei";
import { useEffect } from "react";
import loaderBackgroundImage from "../../assets/images/ui/fondo.jpg";
import useStore from "../store/mainStore";
import Pie from "./Pie";

const Loading = () => {
  const setSceneActive = useStore.getState().setSceneActive;

  useEffect(() => {
    return () => {
      setSceneActive(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Html fullscreen zIndexRange={[10, 0]}>
      <div className="flex justify-start items-center w-full h-full z-10">
        <Pie />

        <div className="flex relative w-full h-full inset-0 justify-center items-center">
          <div
            className="flex absolute w-full h-full justify-center items-center z-0"
            style={{
              backgroundImage: `url(${loaderBackgroundImage})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              background: "#ffffff",
              //   "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)",
              // animation: "gradient 1s ease infinite",
            }}
          />
        </div>
      </div>
    </Html>
  );
};

export default Loading;
