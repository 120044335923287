import { PlaneBufferGeometry, Vector3 } from "three";
import ListHotspots from "../../others/ListHotspots";
import HotSpotEffect from "../effects/HotSpotEffect";
import HoverMesh from "../effects/HoverMesh";
import useHotspotStore from "../store/hotspotsStore";

const HotSpotTraffic = () => {
  const { TRAFFIC1, TRAFFIC2 } = ListHotspots();
  const trafficHotspot = useHotspotStore.getState().trafficHotspot;

  return (
    <>
      {/* <HoversTraffic /> */}
      <HotSpotEffect
        hotspotData={trafficHotspot[0]}
        position={new Vector3(23.35, 8, -25)}
        currentHotspot={TRAFFIC1}
      />
      <HotSpotEffect
        hotspotData={trafficHotspot[1]}
        position={new Vector3(-12.336, 8, -29.141)}
        currentHotspot={TRAFFIC2}
      />
    </>
  );
};

const HoversTraffic = () => {
  return (
    <>
      <HoverMesh
        position={[-11.97, 1.5, -30.616]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(19, 2.5)}
      />
      <HoverMesh
        position={[-11.97, 1.5, -27.568]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(19, 2.5)}
      />
      <HoverMesh
        position={[-21.29, 1.5, -29.101]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(3, 2.5)}
      />
      <HoverMesh
        position={[-2.659, 1.5, -29.101]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(3, 2.5)}
      />

      <HoverMesh
        position={[24.47, 1.5, -0.06]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(96, 5)}
      />
      <HoverMesh
        position={[22.194, 1.5, -0.06]}
        rotation={[Math.PI, Math.PI / 2, 0]}
        geometry={new PlaneBufferGeometry(96, 5)}
      />
      <HoverMesh
        position={[23.276, 1.5, -48.086]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(3, 5)}
      />
      <HoverMesh
        position={[23.276, 1.5, 47.913]}
        rotation={[Math.PI, 0, 0]}
        geometry={new PlaneBufferGeometry(3, 5)}
      />
    </>
  );
};

export default HotSpotTraffic;
