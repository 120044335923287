import { useEffect, useLayoutEffect, useState } from "react";
import {
  isIOS,
  isMacOs,
  isMobile,
  isSafari,
  isTablet,
} from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { routes } from "../managers/RoutesManager";
import useModalStore from "../modals/StoreModals";

const isPortrait: boolean = window.innerWidth < window.innerHeight;

const OnBoarding = () => {
  const history = useNavigate();
  function LoadingScene() {
    // Habilitar pantalla de carga

    // Nos movemos a al escena
    history(routes.SCENE);
  }

  useEffect(() => {
    console.log("isMobile", isMobile);
    console.log("isPortrait", isPortrait);
  });

  const [hover, setHover] = useState<boolean>(false);

  const heightMaxForChange = 785;

  const [initial, setToInitial] = useState<boolean>(
    isTablet
      ? window.innerHeight < 715
      : window.innerHeight < heightMaxForChange
  );

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      // console.log(window.innerHeight);
      if (
        isTablet
          ? window.innerHeight < 715
          : window.innerHeight < heightMaxForChange
      ) {
        setToInitial(true);
      } else {
        setToInitial(false);
      }
    });
  }, []);

  const [open, setOpen] = useState<boolean>(false);

  const Pastilla = () => {
    return !initial ? (
      <div>
        <div
          className={`absolute bottom-0 pb-0 lg:pb-4 xl:pb-9 pt-0 lg:pt-4 xl:pt-5 px-10`}
          style={{
            borderRadius: "12px 12px 0px 0px",
            backgroundColor: "#c7e8e6",
            width: !isMobile ? "27rem" : "",
            maxWidth: !isMobile ? "27rem" : "",
          }}
        >
          <h1 className="font-RobotoRegular text-2xl font-medium">
            Learn more about <br /> NTT Smart Solutions
          </h1>

          <div
            className="flex flex-row mt-2 "
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
          >
            <h2
              className="font-RobotoBold font-bold cursor-pointer hover:underline"
              onMouseEnter={() => console.log("hola")}
              onMouseOver={() => console.log("hola")}
              onClick={() => {
                const setOpenVideoModal =
                  useModalStore.getState().setOpenVideoModal;
                setOpenVideoModal(true);
              }}
            >
              WATCH VIDEO
            </h2>
          </div>
        </div>
      </div>
    ) : (
      <div className="mt-2">
        <h1
          className="font-RobotoRegular text-lg font-medium"
          style={{ color: "#0072bc" }}
        >
          Learn more about NTT Smart Solutions:
        </h1>
        <h2
          className="font-RobotoBold font-bold cursor-pointer hover:underline"
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onClick={() => {
            const setOpenVideoModal =
              useModalStore.getState().setOpenVideoModal;
            setOpenVideoModal(true);
          }}
        >
          WATCH VIDEO
        </h2>
      </div>
    );
  };

  return (
    <>
      <div
        className="w-full h-full pl-14 grid grid-cols-5/5 xl:grid-cols-4/6 relative overflow-y-auto overflow-x-hidden"
        // style={{
        //   gridTemplateColumns: "4fr 6fr",
        // }}
      >
        <div className="flex flex-col z-10 relative">
          <div className="mr-40">
            <img
              className="w-52 pt-7 pb-3"
              src="https://smartworld.nttdata.com/assets/images/ui/ntt_logo.png"
              alt="logo"
              style={{
                borderRadius: "0px 0px 12px 12px",
                backgroundColor: "#fcd4c9",
              }}
            />
            <h1 className="min-w-full font-RobotoRegular text-3xl lg:text-4xl 2xl:text-5xl font-medium mt-7 lg:mt-12 xl:mt-16">
              Explore & discover our
            </h1>
            <h1
              className="min-w-full font-RobotoRegular text-3xl lg:text-4xl 2xl:text-5xl font-medium"
              style={{ color: "#0072bc" }}
            >
              Smart World
            </h1>
            <p
              className="font-RobotoRegular font-medium mt-4 lg:mt-5 xl:mt-10 text-base xl:text-boardingLG 2xl:text-boardingXL"
              style={{ paddingRight: isTablet ? "15%" : "0%" }}
            >
              NTT continues to move forward in developing its Smart Solutions to
              address new challenges facing organizations worldwide and
              contribute to local economic development and sustainability goals.
              Discover how they can be relevant to you.
            </p>
            <div className="">
              <button
                className="font-RobotoBold py-4 rounded-md mt-4 lg:mt-8 text-lg box-border border-2 bg-white text-NTT-blue border-NTT-blue hover:bg-NTT-blue hover:text-white"
                style={{
                  minWidth: "13rem",
                }}
                onClick={LoadingScene}
              >
                START EXPLORING
              </button>
            </div>
          </div>
          <Pastilla />
        </div>
        <div
          className={`absolute h-full ${
            isIOS || isMacOs ? "w-[100vh]" : ""
          }  right-0 flex justify-end  `}
          style={{ right: isIOS && isTablet ? -40 : 0 }}
        >
          <img
            className=" object-contain mx-auto w-full"
            src="https://smartworld.nttdata.com/assets/images/cover_image.jpg"
            alt=""
            style={{
              display: isMobile && isPortrait && !isTablet ? "none" : "",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default OnBoarding;
