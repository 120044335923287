import styled from "styled-components";
import { Zones } from "../../others/ListZones";
import useStore from "../store/mainStore";
import useZoneDescriptionStore from "../store/zonesStore";

const Footer = () => {
  const currentZone = useStore((state) => state.currentZone);
  const setCurrentZone = useStore((state) => state.setCurrentZone);
  const parksZoneDescription = useZoneDescriptionStore(
    (state) => state.parksZoneDescription
  );
  const trafficZoneDescription = useZoneDescriptionStore(
    (state) => state.trafficZoneDescription
  );
  const transitZoneDescription = useZoneDescriptionStore(
    (state) => state.transitZoneDescription
  );
  const venuesZoneDescription = useZoneDescriptionStore(
    (state) => state.venuesZoneDescription
  );
  const buildingsZoneDescription = useZoneDescriptionStore(
    (state) => state.buildingsZoneDescription
  );

  return (
    <div className="absolute inset-x-0 bottom-0 z-10">
      <div className="flex flex-col w-full sm:w-[70%] md:w-[60%] xl:w-[50%] 2xl:w-[40%] mx-auto ">
        {currentZone !== Zones.EMPTY && (
          <div className="relative col-span-5 bg-[rgb(186,210,237)] px-9 pt-3 pb-4 rounded-xl">
            <div className="absolute top-0 right-0 mt-3 mr-3">
              <img
                className="w-5 cursor-pointer"
                src="https://smartworld.nttdata.com/assets/images/ui/X.png"
                onClick={() => setCurrentZone(Zones.EMPTY)}
              />
            </div>
            <h1 className="font-RobotoMedium text-xl tracking-wide text-[#176FC0]">
              {currentZone === Zones.PARKS
                ? "Parks"
                : currentZone === Zones.TRAFFIC
                ? "Traffic"
                : currentZone === Zones.TRANSIT
                ? "Transit"
                : currentZone === Zones.VENUES
                ? "Venues"
                : currentZone === Zones.BUILDINGS
                ? "Buildings"
                : null}
            </h1>
            <p className="font-RobotoRegular text-popPup mt-1">
              {currentZone === Zones.PARKS
                ? parksZoneDescription
                : currentZone === Zones.TRAFFIC
                ? trafficZoneDescription
                : currentZone === Zones.TRANSIT
                ? transitZoneDescription
                : currentZone === Zones.VENUES
                ? venuesZoneDescription
                : currentZone === Zones.BUILDINGS
                ? buildingsZoneDescription
                : null}
            </p>
          </div>
        )}

        <div
          className="flex flex-row justify-evenly bg-opacity-75 bg-white"
          style={{
            borderRadius: "15px 15px 0px 0px",
          }}
        >
          <button
            onClick={() => {
              if (currentZone === Zones.PARKS) {
                setCurrentZone(Zones.EMPTY);
              } else {
                setCurrentZone(Zones.PARKS);
              }
            }}
            className={`flex flex-col items-center text-black font-bold pb-2 px-4  relative`}
            style={{ borderRadius: "15px 0px 0px 0px", outline: "none" }}
          >
            {currentZone === Zones.PARKS && (
              <img
                src="https://smartworld.nttdata.com/assets/images/ui/triangle.png"
                className="absolute"
              />
            )}
            <img
              src="https://smartworld.nttdata.com/assets/images/ui/park.svg"
              className="w-16"
              alt=""
            />
            <span className="text-sm font-medium mt-1">Parks</span>
          </button>

          <button
            style={{ outline: "none" }}
            onClick={() => {
              if (currentZone === Zones.TRAFFIC) {
                setCurrentZone(Zones.EMPTY);
              } else {
                setCurrentZone(Zones.TRAFFIC);
              }
            }}
            className={`flex flex-col items-center text-black font-bold pb-2 px-4  relative`}
          >
            {currentZone === Zones.TRAFFIC && (
              <img
                src="https://smartworld.nttdata.com/assets/images/ui/triangle.png"
                className="absolute"
              />
            )}
            <img
              src="https://smartworld.nttdata.com/assets/images/ui/semaphore.svg"
              className="w-16"
              alt=""
            />
            <span className="text-sm font-medium mt-1">Traffic</span>
          </button>

          <button
            style={{ outline: "none" }}
            onClick={() => {
              if (currentZone === Zones.TRANSIT) {
                setCurrentZone(Zones.EMPTY);
              } else {
                setCurrentZone(Zones.TRANSIT);
              }
            }}
            className={`flex flex-col items-center text-black font-bold pb-2 px-4  relative`}
          >
            {currentZone === Zones.TRANSIT && (
              <img
                src="https://smartworld.nttdata.com/assets/images/ui/triangle.png"
                className="absolute"
              />
            )}
            <img
              src="https://smartworld.nttdata.com/assets/images/ui/bus.svg"
              className="w-16"
              alt=""
            />
            <span className="text-sm font-medium mt-1">Transit</span>
          </button>

          <button
            style={{ outline: "none" }}
            onClick={() => {
              if (currentZone === Zones.VENUES) {
                setCurrentZone(Zones.EMPTY);
              } else {
                setCurrentZone(Zones.VENUES);
              }
            }}
            className={`flex flex-col items-center text-black font-bold pb-2 px-4   relative `}
          >
            {currentZone === Zones.VENUES && (
              <img
                src="https://smartworld.nttdata.com/assets/images/ui/triangle.png"
                className="absolute"
              />
            )}
            <img
              src="https://smartworld.nttdata.com/assets/images/ui/stadium.svg"
              className="w-16"
              alt=""
            />
            <span className="text-sm font-medium mt-1">Venues</span>
          </button>
          <button
            onClick={() => {
              if (currentZone === Zones.BUILDINGS) {
                setCurrentZone(Zones.EMPTY);
              } else {
                setCurrentZone(Zones.BUILDINGS);
              }
            }}
            className={`flex flex-col items-center text-black font-bold pb-2 px-4  relative`}
            style={{ borderRadius: "0px 15px 0px 0px", outline: "none" }}
          >
            {currentZone === Zones.BUILDINGS && (
              <img
                src="https://smartworld.nttdata.com/assets/images/ui/triangle.png"
                className="absolute"
              />
            )}
            <img
              src="https://smartworld.nttdata.com/assets/images/ui/building.svg"
              className="w-16"
              alt=""
            />
            <span className="text-sm font-medium mt-1">Buildings</span>
          </button>
        </div>
      </div>

      <div className="w-full h-3 bg-[#176FC0]" />
    </div>
  );
};

export default Footer;
