import { Plane, Sky } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { isIOS, isMacOs, isTablet } from "react-device-detect";
import { MeshBasicMaterial, PlaneGeometry } from "three";
import { Water } from "three-stdlib";
import Hotspot from "./components/effects/Hotspot";
import HotspotsHover from "./components/effects/HotspotsHover";
import HoverMeshV2 from "./components/effects/HoverMeshV2";
import Ocean from "./components/effects/Ocean";
import WaterEffect from "./components/effects/Water";
import ZonesEffect from "./components/effects/ZonesEffect";
import ControlsManager from "./components/managers/ControlsManager";
import CubemapManager from "./components/managers/CubemapManager";
import LightManager from "./components/managers/LightManager";
import CIUDAD21 from "./components/models/CIUDAD21";
import Traffic260122 from "./components/models/Traffic260122_1";
import useStore from "./components/store/mainStore";
import Loading from "./components/ui/Loading";

const Scene = () => {
  const sceneActive = useStore((state) => state.sceneActive);

  return (
    <Canvas
      mode="concurrent"
      dpr={window.devicePixelRatio}
      performance={{ current: 1, min: 0.75, max: 1, debounce: 200 }}
      gl={{
        powerPreference: "high-performance",
        antialias: true,
        alpha: true,
      }}
      className="flex absolute inset-0 w-full h-full"
      shadows
      // linear={true}
      // flat
    >
      <Suspense fallback={<Loading />}>
        <CubemapManager />
        <CIUDAD21 />
        {isIOS || isMacOs ? (
          <mesh
            geometry={new PlaneGeometry(50, 50)}
            material={new MeshBasicMaterial({ color: "aqua" })}
            rotation={[-Math.PI / 2, 0, 0]}
            position={[-8, -0.1, -3]}
          />
        ) : (
          <WaterEffect />
        )}
        {/* <WaterEffect /> */}
        {/* <Ocean size={{ width: 50, height: 50 }} position={[-8, -0.1, -3]} /> */}
        <ZonesEffect />
        <Traffic260122 />
        <Sky
          distance={1000}
          sunPosition={[-0.5, 1, 0.75]}
          inclination={0}
          azimuth={0.75}
          rayleigh={0.5}
          turbidity={5}
        />

        <HotspotsHover />
        {/* Con esto evito que la pantalla del Loading se mueva por el movimiento de la camara del jugador */}
        {sceneActive && <ControlsManager />}
      </Suspense>

      <LightManager />
      {/* <Loading /> */}
      {/* <Stats /> */}
    </Canvas>
  );
};

export default Scene;
